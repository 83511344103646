/* spacex fight customer form */
.email-region {
  position: relative;
}
.first-email .MuiInputBase-input {
  padding-right: 0px !important;
  padding-left: 2px !important;
  border: 0px !important;
  color: #a7a9ac;
}
.cc-email {
  position: absolute !important;
  right: 0;
  background-color: #6c757d !important;
  span {
    color: #fff !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 500;
    text-transform: capitalize !important;
    pointer-events: none;
  }
}
.mobile-email-region {
  .cc-email {
    right: 20px !important;
  }
}
.mobile-first-email {
  width: 100%;
  position: relative;
}
.mobile-first-email
  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  margin-top: -10px !important;
}
.mobile-first-email .MuiInput-formControl {
  border: 0px !important;
  margin-left: -5px;
}
.mobile-data-email {
  width: 35%;
  float: left;
  height: 40px;
  font-family: "Roboto", Myanmar3;
  font-weight: 700;
  font-size: 16px;
  overflow-anchor: none !important;
}
.mobile-data-email
  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  margin-top: 4px !important;
  border-left: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.passenger_email_div {
  position: relative;
  .MuiCircularProgress-root {
    position: absolute;
    right: 20px;
    top: 8px;
  }
}
.passenger_email_div.mobile_div {
  .MuiCircularProgress-root {
    top: 25px !important;
  }
}
.email_result {
  padding: 15px;
  position: absolute;
  z-index: 999;
  width: 100%;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 1px 3px 0px #e6e7e8;
  border: 1px solid #e3e3e3;
  min-height: 100px;
  transition: 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;

  span {
    font-size: 14px;
  }
  .email_item {
    cursor: pointer;
    display: inline-block;
    line-height: 30px;
  }
  .email_item:hover {
    color: #2cafe8;
  }
}
.mobile_div {
  margin-bottom: 30px !important;
  .email_result {
    border-right: 1px solid #e6e7e8;
    border-left: 1px solid #e6e7e8;
    border-bottom: 1px solid #e6e7e8;
  }
}

.passenger_email {
  font-size: 13px;
  color: grey;
  background-image: url("./../images/search_icon.png");
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: 2% 50%;
  outline: 0;
  border-radius: 5px;
}
.passenger_email.mobile_search {
  background-position: 2% 65% !important;
  padding-bottom: 0 !important;
  input {
    padding-left: 30px !important;
  }
  .MuiInput-formControl {
    margin-bottom: 0 !important;
  }
}
.customerContainer .passenger_email .MuiInputBase-input {
  padding-left: 30px !important;
}
.sleepmodify-customerinfo {
  background-color: rgb(39, 49, 66);
  padding: 0 !important;
}
.sleepmodify-warning {
  background: #ffeaac;
  text-align: center;
  padding: 6px 0px;
  border-radius: 4px;
  min-width: 100%;
}
@media (max-width: 780px) {
  .sleepmodify-warning {
    padding: 10px 10px;
    min-width: 92%;
    margin: 10px 5px 0 5px;
  }
}

.familySelect {
  width: 25%;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 7px;
  .MuiInputBase-input {
    width: 90%;
    border-radius: 7px;
    border: 0px;
  }
}
.familySelectMobile {
  width: 55%;
  margin: 0 0 10px 15px !important;
  background: #fff;
  border-radius: 7px;
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiSelect-select {
    padding: 5px !important;
    font-size: 15px;
  }
}
.familyTitle {
  color: rgb(225, 225, 225);
  font-size: 24px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 700;
  margin: 0 0 10px 0;
}
.familyTitle.mobile {
  padding-left: 15px;
  margin: 20px 0 10px 0;
}
.familyFields {
  background: #fff;
  padding: 10px 15px 20px 15px;
  border-radius: 4px;
  margin-bottom: 25px;
  .MuiGrid-item {
    display: flex;
    flex-direction: column;
    .MuiInputBase-input {
      width: 90%;
    }
  }
  .medicalFields {
    .medicalWrapper {
      display: inline-block !important;
    }
    .radio-group {
      display: flex;
      flex-direction: row;
    }
  }
  .family-info-cell-label {
    font-size: 12px;
    font-weight: bold;
    padding: 5px;
    flex-direction: row;
  }
  .fieldTitle {
    margin-top: 10px;
    font-size: 15px;
  }
  .countryCode {
    margin-top: 8px;
  }
  .searchErrMessage {
    input,
    .MuiSelect-select,
    .selectBox,
    textarea,
    .MuiInputBase-input {
      border: 1px solid #ff0000 !important;
    }
  }
}
.familyFieldsMobile {
  background: #fff;
  padding: 30px 15px 20px 15px !important;
  margin: 0 5px 10px 5px !important;
  width: 97.4% !important;
  border-radius: 5px;
  margin-bottom: 25px !important;
  .mobileContainer {
    margin-top: 10px;
    .MuiInputBase-root {
      width: 100% !important;
      .MuiInputBase-input {
        height: auto !important;
      }
    }
  }
  .familyInput {
    border: 1px solid #e6e7e8;
    margin-bottom: 0px;
    padding: 10px 5px !important;
    border-radius: 5px;
    width: 100% !important;
    height: auto;
  }
  .familyName {
    margin-top: 20px;
  }
  .MuiGrid-item {
    display: flex;
    flex-direction: column;
    .MuiInputBase-input {
      width: 90%;
      height: auto;
    }
    .animal-detail {
      border: 1px solid #e6e7e8;
      outline: none;
    }
  }
  .charter-region-mobile {
    width: 100%;
    .selectBox {
      height: 40px;
    }
    .SingleDatePickerInput {
      width: 95%;
    }
  }
  .MuiFormControl-root {
    width: 100%;
  }
  .searchErrMessage {
    .familyInput {
      border: 1px solid #ff0000 !important;
    }
  }
}
.mobileContainer {
  .mobileNumberDiv {
    .MuiFormControl-root {
      padding-bottom: 0 !important;
    }
    .MuiInputBase-root {
      margin-bottom: 0 !important;
    }
  }
  span.fieldDescriptionMobile {
    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.54);
    margin-bottom: 25px;
    display: inline-block;
  }
}
.animalCheck {
  background: #fff;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
}
.animalCheckMobile {
  background: #fff;
  margin: 5px 5px 0 5px !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  align-items: center;
  display: flex;
  font-size: 15px;
}
.animalFields {
  background: #fff;
  padding: 10px 15px 20px 15px;
  border-radius: 0 0 7px 7px;
  .MuiGrid-item {
    display: flex;
    flex-direction: column;
    .MuiInputBase-input {
      width: 90%;
    }
    .animal-detail {
      border: 1px solid #e6e7e8;
      outline: none;
    }
    .animal-detail::placeholder {
      font-family: "Roboto";
    }
  }
  .searchErrMessage {
    input,
    .MuiSelect-select,
    textarea,
    .selectBox,
    .MuiInputBase-input {
      border: 1px solid #ff0000 !important;
    }
  }
}
.animalFieldsMobile {
  background: #fff;
  padding: 10px 15px 20px 15px;
  margin: 0 5px 10px 5px !important;
  width: 97.4% !important;
  border-radius: 0 0 5px 5px;
  .animalType,
  .animalSize,
  .animalName {
    border: 1px solid #e6e7e8;
    margin-bottom: 15px;
    padding: 10px 5px !important;
    border-radius: 5px;
    width: 100% !important;
  }
  .MuiGrid-item {
    display: flex;
    flex-direction: column;
    .MuiInputBase-input {
      width: 90%;
    }
    .animal-detail {
      border: 1px solid #e6e7e8;
      outline: none;
    }
    .animal-detail::placeholder {
      font-family: "Roboto";
    }
  }
  .searchErrMessage {
    .animalType,
    .animalSize,
    .animalName,
    .animal-detail {
      border: 1px solid #ff0000 !important;
    }
  }
}
.MuiButton-root.Mui-disabled {
  background-color: light-dark(
    rgba(239, 239, 239, 0.3),
    rgba(19, 1, 1, 0.3)
  ) !important;
  color: light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3));
  border-color: light-dark(rgba(118, 118, 118, 0.3), rgba(195, 195, 195, 0.3));
}
.genderFields {
  background: #fff;
  padding: 10px 15px 20px 0;
  .MuiGrid-item {
    display: flex;
    flex-direction: column;
    .MuiInputBase-input {
      width: 90%;
    }
  }
  .searchErrMessage {
    input,
    .MuiSelect-select,
    textarea,
    .selectBox,
    .MuiInputBase-input {
      border: 1px solid #ff0000 !important;
    }
  }
}
.gender-divider,
.fly-divider,
.medical-divider {
  border-top: 1px solid #e6e7e8;
}
.fly-divider {
  margin-top: 20px !important;
}
.medical-divider {
  margin-top: 15px !important;
}
.medicalFields {
  h5 {
    margin: 0 0 10px 0;
    .errMessage {
      font-weight: 400;
      color: #ff0000;
      font-size: 12px;
      margin-left: 10px;
    }
  }
  .medical-flex {
    display: flex;
  }
  .medicalTitle {
    color: #231f20;
    font-size: 14px;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
  }
}
.radio-group {
  display: flex;
  .radio-region {
    display: flex;
    align-items: center;
    margin: 10px 20px 0 0;
    .MuiRadio-root {
      padding: 0 !important;
    }
    span {
      font-size: 14px;
    }
  }
}
.radio-summary {
  textarea {
    width: 100%;
    border-color: #e6e7e8;
  }
  .errMessage {
    font-weight: 400;
    color: #ff0000;
    font-size: 12px;
    display: inline-block;
  }
}
.shuttleBusFields {
  h5 {
    margin: 0 0 10px 0;
  }
  .shuttleBox {
    display: block !important;
    margin-bottom: 6px !important;
    .MuiCheckbox-root {
      padding: 0px !important;
    }
    span {
      font-size: 14px;
      color: #000 !important;
      padding-left: 6px !important;
    }
  }
}
.serviceWarningWeb {
  margin: 15px 0px 0px 0px !important;
  background: white;
  padding: 0px 15px 0px 0px;
  span {
    font-size: 14px;
    font-family: "Roboto";
    color: #231f20 !important;
  }
  svg {
    color: #00aeef !important;
  }
}
.serviceWarningMobile {
  margin-top: 20px !important;
  display: flex;
  align-items: center;
  .MuiButtonBase-root {
    padding-left: 0;
    padding-top: 0;
  }
  span {
    font-size: 14px;
    font-family: "Roboto";
    color: #231f20 !important;
  }
  svg {
    color: #00aeef !important;
  }
}

@media (max-width: 1024px) {
  .searchErrMessage .MuiInputBase-input {
    border: none !important;
  }
  .medical-region-mobile {
    margin-bottom: 20px;
    .medicalTitleMobile {
      font-size: 15px;
      margin: 0px 0 10px 0;
    }
    span {
      font-size: 14px;
      color: black !important;
      font-family: "Roboto", Myanmar3 !important;
    }
    span.errMessage {
      color: #ff0000 !important;
      font-size: 12px !important;
    }
    .radio-summary textarea {
      width: 95%;
      border-color: #e6e7e8;
    }
  }
  .medical-radio-mobile {
    display: flex;
    .radio-region {
      display: flex;
      align-items: center;
      margin: 10px 20px 0 0;
      .MuiRadio-root {
        padding: 0 !important;
      }
      span {
        font-size: 14px;
      }
    }
  }
  .radio-summary {
    .text-summary {
      margin-bottom: 0;
    }
  }
  .shuttleRegion {
    h5 {
      margin-bottom: 5px;
    }
  }
  .genderMobile {
    .gender {
      width: 100% !important;
      border: 1px solid #e6e7e8;
      border-radius: 5px;
      margin-bottom: 10px;
    }
  }
  .familyFieldsMobile {
    .gender {
      width: 100% !important;
      border: 1px solid #e6e7e8;
      border-radius: 5px;
      margin-bottom: 25px;
      height: 40px;
    }
  }
}
.carry_on_baggage,
.checked_bags_baggage {
  width: 100%;
}
.carryOnBaggageMobile {
  .carry_on {
    width: 100% !important;
    height: 40px !important;
    border: 1px solid #e6e7e8;
    border-radius: 5px;
    margin-bottom: 25px;
  }
}
.baggageContainer {
  display: inline-block;
  margin-top: -20px;
}
.perbaggage_weight,
.flybaggage_type {
  color: #a7a9ac;
  font-size: 13px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  margin-top: 9px !important;
  padding-left: 5px !important;
}
.additional_lb input {
  margin-left: 10px;
}
.hazardous_select {
  width: 76% !important;
}
.flybaggage_height input {
  margin-right: 10px !important;
}
.flybaggage_width input {
  margin-right: 4px !important;
  margin-left: -2px;
}
.flybaggage_depth input {
  margin-right: 2px !important;
  margin-left: 3px;
}
.baggage_question {
  display: inline-block;
  color: #231f20;
  font-size: 14px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  line-height: 35px;
  margin-left: -15px;
}
.baggage_multiply {
  position: absolute;
  margin-top: 6px;
  color: #a7a9ac;
}
.multiply_height {
  margin-left: -4px;
}
.baggage_mobile_multiply {
  position: absolute;
  margin-top: 20px;
  margin-left: 5px;
}
.baggage_div {
  width: 21%;
  display: inline-block;
}
.baggage_type_div {
  border: 0;
  margin: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
}
.baggage_height,
.baggage_width {
  margin-right: 10px;
}
.baggage_width,
.baggage_depth,
.baggage_type {
  padding-left: 8px;
}
.baggage_type_text {
  font-family: "Roboto", Myanmar3 !important;
  margin-top: 13px;
  font-size: 15px;
}

.baggage_type select {
  height: 40px !important;
  margin-top: 3px;
}
.baggage_mobileq_div select {
  width: 20% !important;
  margin-right: 10px;
  float: left;
}
.baggage_mobileq_div span {
  font-size: 14px;
}
.baggage_mobileq {
  display: inline-block;
  margin-top: 10px;
}
.additional_baggage_div {
  width: 100%;
  display: inline-block;
}
.additional_baggage_div label {
  color: black !important;
  font-family: "Roboto", Myanmar3 !important;
  font-size: 14px !important;
  display: inline-block;
  width: 100%;
}
.additional_select_mobile {
  width: 20%;
  float: left;
}

.additional_select_mobile select {
  margin-top: 5px;
  height: 40px;
}
.additional_baggage_mobile {
  width: 80%;
  float: left;
}
.additional_baggage_mobile .MuiFormControl-root {
  width: 88%;
}
.additional_baggage_mobile span {
  color: black !important;
  font-family: "Roboto", Myanmar3 !important;
  font-size: 14px !important;
  margin-top: 15px;
  margin-left: 5px;
  display: inline-block;
}
.additional_baggage_mobile .MuiInput-formControl {
  border-color: #e6e7e8 !important;
  border-width: 1px;
  border-style: solid !important;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 20px;
  font-family: "Roboto-bold", Myanmar3 !important;
  color: #231f20;
  font-size: 14px;
  margin-left: 10px;
}
/* spacex fight customer form */

/* spacex flight result page */
.warning-fly {
  position: absolute;
  left: 25vw;
  top: 30vh;
  padding: 20px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  span {
    color: #f50057;
  }
}
.warning-fly-mobile {
  left: 10px !important;
  right: 10px !important;
}
.flyresult {
  .inner-flyresult {
    min-height: 400px;
  }

  .date-region {
    background-color: #273142;
    padding: 10px 0;

    .date-inner {
      width: 889px !important;
      margin: auto;
      position: relative;
      display: flex;
      .date-inner-box {
        width: 40%;
      }
      .SingleDatePickerInput__withBorder {
        border: 0px;
        border-radius: 5px;
      }
      .DateInput {
        width: 170px;
        padding: 5px 10px;
        border-radius: 5px;
      }
      .DateInput_input {
        padding: 0px;
        font-weight: bold;
        color: #000;
        border-bottom: 0px;
      }
      .SingleDatePickerInput_calendarIcon {
        margin: 0px;
        background-color: #ebebeb;
        padding: 10px 15px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        svg {
          margin-top: -5px;
          fill: #000;
        }
      }
      .DateInput_input__focused {
        border-bottom: 0px;
      }

      .SingleDatePicker_picker {
        top: 50px !important;
      }
      .DateInput_fang {
        top: 38px !important;
      }

      .MuiInputBase-formControl {
        border: 1px solid #ebebeb;
        padding: 10px;
        background-color: #fff;
        border-radius: 5px;
        input {
          color: #000;
        }
      }
      .MuiCard-root {
        position: absolute;
        z-index: 999;
        padding: 10px;
        border-radius: 5px !important;
        width: 201px;
        min-height: 80px;
        span {
          width: 100%;
          font-family: "Roboto", Myanmar3 !important;
          display: inline-block;
          cursor: pointer;
          margin-bottom: 10px;
        }
        span:hover {
          color: #00aeef;
        }
      }
    }
  }
  .CalendarDay__selected {
    color: #fff !important;
  }
}
@media (max-width: 780px) {
  .flyresult {
    .date-region {
      .date-inner {
        display: block !important;
        .date-inner-box {
          width: 50%;
        }
        .SingleDatePicker {
          padding-left: 10px;
        }
        .SingleDatePickerInput_calendarIcon {
          padding: 10px 15px !important;
        }
        .SingleDatePicker_picker {
          top: 45px !important;
          left: 10px !important;
        }
        .DayPicker {
          width: auto !important;
          min-width: 0 !important;
        }
        .MuiInputBase-formControl {
          margin-left: 10px;
        }
        .MuiCard-root {
          left: 10px;
        }
      }
    }
  }
}
/* spacex flight result page */

.date-region {
  .date-inner {
    .SingleDatePicker {
      padding-left: 10px;
    }
    .SingleDatePickerInput_calendarIcon {
      padding: 10px 15px !important;
    }
    .SingleDatePicker_picker {
      top: 45px !important;
      left: 10px !important;
    }
    .DayPicker {
      width: auto !important;
      min-width: 0 !important;
    }
    .MuiInputBase-formControl {
      margin-left: 10px;
    }
    .MuiCard-root {
      left: 10px;
    }
  }
}

.hovercraft-date {
  text-align: center;
  position: relative;
  span.showAllDates {
    font-family: "Roboto" !important;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
  }
  .SingleDatePicker {
    padding-right: 10px;
  }
  .leftArrow,
  .rightArrow {
    color: #fff;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    padding: 8px 12px;
    position: relative;
    font-size: 22px;
    top: 0;
  }
  .arrowtrue {
    opacity: 0.5;
    pointer-events: none;
  }
  .SingleDatePickerInput__disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    color: #00aeef !important;
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #00aeef !important;
  }
  .DateInput__disabled {
    background-color: #f2f2f2 !important;
  }
}
@media (max-width: 780px) {
  .hovercraft-date {
    text-align: center;
    position: relative;
    .allDatesDiv {
      margin-left: 10px;
      position: initial;
    }
  }
}
